import { createApp } from "vue";
import App from "@/App.vue";
import { createWebHistory, createRouter } from "vue-router";
import store from "./store/index";
import nProgress from "nprogress";
import Axios from "axios";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "nprogress/nprogress.css";

const toastOptions = {
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
};

// styles

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// routes

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: () => import("./views/Dashboard.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/polls",
    name: "polls",
    component: () => import("./views/polls/Index"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/polls/create",
    name: "polls.create",
    component: () => import("./views/polls/Create"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/polls/:id",
    name: "polls.show",
    component: () => import("./views/polls/Show"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/polls/:id/edit",
    name: "polls.edit",
    component: () => import("./views/polls/Edit"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "users",
    component: () => import("./views/users/Index"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users/:id",
    name: "users.show",
    component: () => import("./views/users/Show"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/post/:id",
    name: "post.show",
    component: () => import("./views/users/Post"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/discussions",
    name: "discussions",
    component: () => import("./views/discussions/Index"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/discussions/create",
    name: "discussions.create",
    component: () => import("./views/discussions/Create"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/discussions/:id",
    name: "discussions.show",
    component: () => import("./views/discussions/Show"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/discussions/:id/edit",
    name: "discussions.edit",
    component: () => import("./views/discussions/Edit"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/elections",
    name: "elections",
    component: () => import("./views/elections/Index"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/elections/create",
    name: "elections.create",
    component: () => import("./views/elections/Create"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/elections/:id",
    name: "elections.show",
    component: () => import("./views/elections/Show"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/elections/:id/edit",
    name: "elections.edit",
    component: () => import("./views/elections/Edit"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ratings",
    name: "ratings",
    component: () => import("./views/ratings/Index"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ratings/create",
    name: "ratings.create",
    component: () => import("./views/ratings/Create"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ratings/:id",
    name: "ratings.show",
    component: () => import("./views/ratings/Show"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/ratings/:id/edit",
    name: "ratings.edit",
    component: () => import("./views/ratings/Edit"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile/update",
    name: "profile.update",
    component: () => import("./views/auth/UpdateProfile"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/password/reset",
    name: "password.reset",
    component: () => import("./views/auth/ResetPassword"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/auth/Login.vue"),
    meta: {
      requiresAuth: false,
      hideForAuth: true,
    },
  },
  {
    path: "/password/forgot",
    name: "password.forgot",
    component: () => import("./views/auth/ForgotPassword.vue"),
    meta: {
      requiresAuth: false,
      hideForAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0, behavior: "smooth" };
  },
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    nProgress.start();
  }
  next();
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
      return;
    }
  }

  if (to.matched.some((record) => record.meta.hideForAuth)) {
    if (store.getters.isLoggedIn) {
      next({ path: "/" });
      return;
    }
  }
  next();
});

router.afterEach((to, from) => {
  nProgress.done();
});

const token = localStorage.getItem("xauth");
if (token) {
  Axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

const app = createApp(App);
app.provide("baseUrl", process.env.VUE_APP_BASE_URL);
app.provide("formatDate", function (date) {
  let newDate = new Date(date);
  return newDate.toLocaleDateString();
});
app.use(store).use(router).use(Toast, toastOptions).mount("#app");
