/* eslint-disable */
import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    token: localStorage.getItem("xauth") || "",
    user: JSON.parse(localStorage.getItem("authuser")) || {},
    userSidebarVisible: false,
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    user: (state) => state.user,
  },
  mutations: {
    SET_USER_SIDEBAR_VISIBLE(state, value) {
      state.userSidebarVisible = value;
    },
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, token, user) {
      state.status = "success";
      state.token = token;
      state.user = user;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
      state.user = null;
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios
          .post(`${process.env.VUE_APP_BASE_URL}auth/login`, user)
          .then((res) => {
            const token = res.data.token;
            const newuser = res.data.user;

            localStorage.setItem("xauth", token);
            localStorage.setItem("authuser", JSON.stringify(newuser));
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            commit("auth_success", token, newuser);
            resolve(res);
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("xauth");
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("logout");
        localStorage.removeItem("xauth");
        localStorage.removeItem("authuser");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
  },
  modules: {},
});