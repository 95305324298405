<template>
  <div id="app">
    <admin-layout v-if="isAuthRoute"/>
  <router-view v-else />
  </div>
  
</template>

<script>
import AdminLayout from '@/layouts/Admin.vue';
import nProgress from 'nprogress';
import store from './store/index';
import axios from 'axios';
export default {
  components: {AdminLayout},
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    },
    currentRouteName() {
        return this.$route.name;
    },
    isAuthRoute(){
      return !(['login', 'password.forgot'].includes(this.currentRouteName));
    }
  },
  created(){
    axios.interceptors.request.use((config) => {
        nProgress.start();
        // if(!this.isLoggedIn){
        //   this.$router.go('/login');
        // }
        return config
    }, error => {
        nProgress.done();
        return Promise.reject(error);
    });
        axios.interceptors.response.use((response) => {
            nProgress.done();
            return response;
        }, function (err) {
            let res = err.response;
            nProgress.done();
            return new Promise(function (resolve, reject) {
                if (res.status === 401 && res.config && !res.config.__isRetryRequest) {
                    store.dispatch('logout');
                    window.location.reload();
                }
                reject(err);
                throw err;
            });
        });
  },
  methods: {
    logout: function() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
  }
}
</script>