<template>
  <div>
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <nav
        class="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4"
      >
        <div
          class="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4"
        >
          <!-- Brand -->
          <a
            class="text-white text-sm uppercase hidden lg:inline-block font-semibold"
            href="javascript:void(0)"
          >
            {{ breadcrumb }}
          </a>
          <!-- User -->
          <ul
            class="flex-col md:flex-row list-none items-center hidden md:flex"
          >
            <div>
              <a
                class="text-blueGray-500 block"
                href="#choice9ja"
                ref="btnDropdownRef"
                v-on:click="toggleDropdown($event)"
              >
                <div class="items-center flex">
                  <p class="px-4 text-white">{{ user.username }}</p>
                  <span
                    class="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full"
                  >
                    <img
                      alt="..."
                      class="w-full rounded-full align-middle border-none shadow-lg"
                      :src="user.profilePic"
                    />
                  </span>
                </div>
              </a>
              <div
                ref="popoverDropdownRef"
                class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
                v-bind:class="{
                  hidden: !dropdownPopoverShow,
                  block: dropdownPopoverShow,
                }"
              >
                <router-link
                  :to="{ name: 'password.reset' }"
                  class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                >
                  Reset Password
                </router-link>
                <router-link
                  :to="{ name: 'profile.update' }"
                  class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                >
                  Update Profile
                </router-link>
                <a
                  @click="logout"
                  href=""
                  class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                >
                  Logout
                </a>
              </div>
            </div>
          </ul>
        </div>
      </nav>
      <!-- Header -->
      <div class="relative bg-emerald-600 md:pt-32 pb-32 pt-12">
        <div class="px-4 md:px-10 mx-auto w-full">
          <div>
            <!-- Card stats -->
            <div class="flex flex-wrap"></div>
          </div>
        </div>
      </div>

      <div class="px-4 md:px-10 mx-auto w-full -m-24">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import { createPopper } from "@popperjs/core";
import axios from 'axios';
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import { useToast } from "vue-toastification";

export default {
  name: "admin-layout",
  inject: ['baseUrl', "formatDate"],
  async mounted() {
    // this.getUser();
  },
  data() {
    return {
      dropdownPopoverShow: false,
      user: {'username':'TheChoice9ja', 'profilePic':''},
      toast: useToast(),
    };
  },
  components: {
    Sidebar,
  },
  computed: {
    breadcrumb() {
      let path = this.$route.path;

      return path.replace("/", "").replace("-", " ");
    },
  },
  methods: {
    toggleDropdown: function (event) {
      event.preventDefault();
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.go("/login");
      });
    },
    // async getUser() {
    //     await axios
    //       .get(this.baseUrl + 'users/me')
    //       .then((response) => {
    //         const { data } = response.data;
    //         this.user = data;
    //       })
    //       .catch((error) => {
    //         this.toast.error('Error getting Admin details!');
    //       });
    // },
  },
};
</script>
